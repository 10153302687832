<template>
	<div class="open-success-page">
		<div class="step-box">
			<div class="step-item">
				<van-image width="20" :src="resourceURL + 'images/step01_h.png'"/>
				<p>填写资料</p>
			</div>
		
			<div class="step-item">
				<img :src="resourceURL + 'images/step_line_h.png'" alt="">
			</div>
		
			<div class="step-item">
				<van-image width="20" :src="resourceURL + 'images/step02_h.png'"/>
				<p>身份验证</p>
			</div>
			<div class="step-item">
				<img :src="resourceURL + 'images/step_line_h.png'" alt="">
			</div>
			<div class="step-item">
				<van-image width="20" :src="resourceURL + 'images/step03_h.png'"/>
				<p>提交审核</p>
			</div>
		</div>
		
		<van-row class="content">
			<van-col span="24">
				<img :src="icon" alt="" class="icon-success">
			</van-col>
			<van-col span="24" class="notice-title">审核失败</van-col>
			<van-col span="24" style="margin: 20px 0 0 0;color: #555;">{{remarks}}</van-col>
		</van-row>
		<div class="common_block">
			<van-button type="warning" block @click="nextStep">去修改</van-button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'account_success',
		data() {
			return {
				resourceURL: this.$axios.defaults.resourceURL,
				
				icon: this.$axios.defaults.resourceURL + "images/icon_open_user_fail.png",

				token: "",
				remarks: ""

			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.token = this.$route.query.token

				this.$axios({
					method: 'post',
					url: 'account_open/info',
					data: {
						token: this.token
					}
				}).then ((res) => {
					if (res.success) {
						this.remarks = res.data.remarks
					}else{
						this.$toast.fail(res.error_text)
					}
				})
			}
		},
		methods: {
			
			nextStep() {
				this.$axios({
					method: 'post',
					url: 'account_open/recover',
					data: {
						token: this.token
					}
				}).then ((res) => {
					if (res.success) {
						this.$router.replace('/account/userinfo?token=' + this.token)
					}else{
						this.$toast.fail(res.error_text)
					}
				})
				
			}
		},
	}
</script>